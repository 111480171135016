// Goodwyns

$tan: #efe7d7;
$darktan: #d7d1af;
$font-grey: #555555;
$purple: #574874;

a, a:link, a:visited, a:focus, a:hover, a:active{ color:none; text-decoration: none;}

.page-oceanic-pet-food{
  #body-container{
    .breadcrumb{
      display: none;
    }
    #node{
      article{
        #content-before{
          max-width: 100%;
          margin-bottom: 40px;
          .region{
            .block{
              .block-content{
                form{
                  div{
                    div.views-exposed-form{
                      div.views-exposed-widgets{
                        background-color: $tan;
                        padding: 22px;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        *{
                          box-sizing: border-box;
                        }
                        .views-widget-filter-title{
                          flex:1;
                          min-width: 23%;
                          padding: 0px 10px;
                          @media (max-width: 750px) {
                            min-width: 48%;
                          }
                          @media (max-width: 400px) {
                            min-width: 100%;
                          }
                            label{
                              font-weight: 700;
                              font-size: 20px;
                              color: $font-grey;
                            }
                          .views-widget{
                            .form-item{
                              input{
                                width: 100%;
                                border-radius: 3px;
                                padding: 5px 7px;
                                cursor: pointer;
                                border:1px solid $darktan;
                              }
                            }
                          }
                        }
                        .views-widget-filter-field_type_value{
                          flex:1;
                          min-width: 23%;
                          padding: 0px 10px;
                          @media (max-width: 750px) {
                            min-width: 48%;
                          }
                          @media (max-width: 400px) {
                            min-width: 100%;
                          }
                            label{
                              font-weight: 700;
                              font-size: 20px;
                              color: $font-grey;
                            }

                          .views-widget{
                            .form-item{
                              select{
                                width: 100%;
                                border-radius: 3px;
                                cursor: pointer;
                                border:1px solid $darktan;
                                -webkit-appearance: none;
                                -moz-appearance: none;
                                appearance: none;
                                padding: 5px 7px;
                                background: #fff url("/sites/all/themes/goodwyns/img/select-arrow-down.svg") no-repeat top 50% right 10px !important;
                                option{
                                  min-height: 20px;
                                  padding: 5px 7px;
                                }
                              }
                            }
                          }
                        }
                        .views-widget-filter-field_age_value{
                          flex:1;
                          min-width: 23%;
                          padding: 0px 10px;
                          @media (max-width: 750px) {
                            min-width: 48%;
                          }
                          @media (max-width: 400px) {
                            min-width: 100%;
                          }
                            label{
                              font-weight: 700;
                              font-size: 20px;
                              color: $font-grey;
                            }

                          .views-widget{
                            .form-item{
                              select{
                                width: 100%;
                                border-radius: 3px;
                                cursor: pointer;
                                background: #fff url("/sites/all/themes/goodwyns/img/select-arrow-down.svg") no-repeat top 50% right 10px !important;
                                border:1px solid $darktan;
                                -webkit-appearance: none;
                                -moz-appearance: none;
                                appearance: none;
                                padding: 5px 7px;
                                option{
                                  min-height: 20px;
                                  padding: 5px 7px;
                                }
                              }
                            }
                          }
                        }
                        .views-widget-filter-field_product_variety_tid{
                          flex:1;
                          min-width: 23%;
                          padding: 0px 10px;
                          @media (max-width: 750px) {
                            min-width: 48%;
                          }
                          @media (max-width: 400px) {
                            min-width: 100%;
                          }

                            label{
                              font-weight: 700;
                              font-size: 20px;
                              color: $font-grey;
                            }

                          .views-widget{
                            .form-item{
                              select{
                                width: 100%;
                                border-radius: 3px;
                                -webkit-appearance: none;
                                -moz-appearance: none;
                                appearance: none;
                                padding: 5px 7px;
                                cursor: pointer;
                                background: #fff url("/sites/all/themes/goodwyns/img/select-arrow-down.svg") no-repeat top 50% right 10px !important;
                                border:1px solid $darktan;
                                option{
                                  min-height: 20px;
                                  padding: 5px 7px;
                                }
                              }
                            }
                          }
                        }
                        .views-submit-button{
                          width: 50%;
                          padding-top: 20px;
                          input{
                            background: $purple;
                            text-decoration: none;
                            color: white;
                            border-radius: 5px;
                            display: block;
                            width: 80%;
                            margin: 10px auto;
                            padding: 10px 0px;
                            cursor: pointer;

                              &:hover{
                                opacity: 0.8;
                              }
                          }
                        }
                        .views-reset-button{
                          width: 50%;
                          padding-top: 20px;
                          input{
                            background: $purple;
                            text-decoration: none;
                            color: white;
                            border-radius: 5px;
                            display: block;
                            width: 80%;
                            margin: 10px auto;
                            padding: 10px 0px;
                            cursor: pointer;

                              &:hover{
                                opacity: 0.8;
                              }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        #content{
          max-width: 100%;
          .region{
            .block{
              .block-content{
                .view{
                  .view-content{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: space-around;
                    .views-row{
                      flex:1;
                      margin: 10px;
                      min-width: 22%;
                      max-width: 22%;

                      @media (max-width: 1250px) {
                        min-width: 31%;
                        max-width: 31%;
                      }
                      @media (max-width: 1070px) {
                        min-width: 47%;
                        max-width: 47%;
                      }
                      @media (max-width: 834px) {
                        min-width: 46%;
                        max-width: 46%;
                      }
                      @media (max-width: 625px) {
                        min-width: 100%;
                        max-width: 100%;
                      }

                      .views-field{
                        span.field-content{
                          .product-teaser{
                            width: 100%;
                            border-radius: 5px;
                            border: 1px solid $darktan;
                            box-sizing: border-box;

                            *{
                              box-sizing: border-box;
                            }

                            .product-teaser-inner{
                              height: 100%;
                              position: relative;

                              .product-teaser-title{
                                display: block;
                                width: 100%;
                                font-size: 20px;
                                min-height: 74px;
                                max-height: 74px;
                                line-height: 1;
                                padding: 10px;
                                display: block;
                                a{
                                  font-size: 20px;
                                  color: $font-grey;
                                  text-decoration: none;
                                }
                              }
                              .grouped{
                                min-height: 300px;
                                width: 100%;
                                position: relative;
                                .entity{
                                  width: 100%;
                                  text-align: center;

                                  img{
                                    max-width: 100%;
                                    margin: 0px auto;
                                    display: block;
                                  }

                                  .field-product-variety{
                                    font-size: 14px;
                                    font-weight: 400;
                                    background-color: $tan;
                                    border-top: 1px solid $darktan;
                                    height: 38px;
                                    padding: 5px 0px;
                                    color: $font-grey;
                                    font-weight: bold;
                                  }
                                  .field-description{
                                    background-color: $tan;
                                    font-size: 14px;
                                    font-weight: 400;
                                    padding-top: 1em;
                                    color: $font-grey;
                                  }
                                  .field-commerce-price{
                                    background-color: $tan;
                                    width: 100%;
                                    display: block;
                                    padding: 10px 0px 20px 0px;
                                    text-align: center;
                                    table{
                                      display: block;
                                      margin: 0px auto;
                                      width: 130px;
                                      tbody{
                                        border-top: 0px;
                                      }
                                        tr{
                                          border-bottom: 0px;
                                          .price-label{
                                          }
                                          .price-amount{
                                            padding: 0px;
                                            color: $font-grey;
                                          }
                                        }
                                      }
                                    }
                                }
                              }

                              .product-teaser-bottom{
                                background-color: $tan;
                                padding: 10px 0px;
                                min-height: 40px;
                                text-align: center;
                                .button{
                                  background: #80ce43 url(/sites/all/themes/website/img/add-button-arrow.png) 80% 50% no-repeat;
                                  position: absolute;
                                  bottom: 0;
                                  left: 10%;
                                  text-decoration: none;
                                  color: white;
                                  padding: 10px 40px 10px 10px;
                                  border-radius: 5px;
                                  display: block;
                                  width: 80%;
                                  margin: 10px auto;
                                  border: none !important;

                                  &:hover{
                                    opacity: 0.8;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
#header-container{
  #basket{
    position: absolute;
    top:20px;
    right: 20%;
  }
}


.page-cart{
  #body-container{
    #node{
      article{
        #content{
          .region{
            .block{
              .block-content{
                .view{

                  .view-header{
                    #basket-header{
                      display: flex;
                      flex-direction: row;
                      flex-wrap: wrap;
                      justify-content: flex-start;
                      .basket-heading{
                        display: block;

                        &#column-1{
                          flex:4;
                        }
                        &#column-2{
                          flex:1;
                          text-align: center;
                        }
                        &#column-3{
                          flex:1;
                          text-align: right;
                        }
                        &#column-4{
                          flex:1;
                          text-align: right;
                        }
                      }
                    }
                  }

                  .view-content{
                    .commerce-line-item-views-form{
                      form{
                        div{
                          .views-row{
                            .views-field{
                              .field-content{
                                .cart-item{
                                  display: flex;
                                  flex-direction: row;
                                  flex-wrap: wrap;
                                  justify-content: flex-start;
                                  height: 120px;
                                  padding: 10px 0px;
                                  border-bottom: 1px solid rgba(0,0,0,0.4);

                                  .cart-item-image{
                                    flex:1;
                                  }
                                  .cart-item-title{
                                    padding: 40px 0px;
                                    flex:3;
                                    a{
                                      text-decoration: none;
                                      color: #333;
                                    }
                                  }
                                  .cart-item-quantity{
                                    padding: 25px 0px;
                                    flex:1;
                                  }
                                  .cart-item-remove{
                                    padding: 40px 0px;
                                    flex:1;
                                  }
                                  .cart-item-price{
                                    padding: 40px 0px;
                                    flex:1;
                                    .field-commerce-total{
                                      font-weight: bold;
                                    }
                                  }
                                }
                              }
                            }
                          }

                          .line-item-total{
                            text-align: right;
                            display: block;
                            padding: 30px 0px 10px 0px;

                            span{
                              font-size: 24px;
                              font-weight: bold;
                            }
                          }
                          #edit-actions{
                            #edit-checkout{
                              background: $purple;
                              text-decoration: none;
                              color: white;
                              border-radius: 5px;
                              display: block;
                              margin: 30px 0px 0px auto;
                              padding: 10px 25px;
                              max-width: 250px;
                              cursor: pointer;
                              &:hover{
                                opacity: 0.8;
                              }
                            }
                          }
                        }
                      }
                    }

                    #commerce-paypal-ec-order-form{
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

footer{
  margin-top:100px;
}
.views-row{
  width: 100%;
  .views-field{
    width: 100%;
  }
}
.cart-item{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: 120px;
  padding: 10px 0px;
  width: 100%;

  .cart-item-image{
    flex:1;
    img{
      max-width: 100%;
      min-width: 100px;
    }
  }
  .cart-item-title{
    padding: 40px 0px;
    flex:2;
    a{
      text-decoration: none;
      color: #333;
    }
  }
  .cart-item-quantity{
    padding: 40px 0px;
    flex:1;
    text-align: right;
  }
  .cart-item-remove{
    padding: 40px 0px;
    flex:1;
    text-align: right;
  }
  .cart-item-itemprice{
    padding: 40px 0px;
    flex:1;
    text-align: right;
  }
  .cart-item-price{
    padding: 40px 0px;
    flex:1;
    text-align: right;
  }
}
.form-item-quantity{
  margin-top: 0px;
}
.submitted-by{
  display: none;
}
.checkout-buttons{
  box-sizing: border-box;
  padding: 0px 20px;
  margin-top: -120px !important;
  .button-operator{
    display: none;
  }
}
#edit-commerce-coupon-coupon-add{
  margin-top: 53px !important;
}
.no-mobile{
  iframe{
    width: 70px !important;
  }
  a{
    vertical-align: top;
  }
}
.extra_pane__node__1061{
  display: none;
}
.commerce-add-to-cart{
  div{
    #edit-submit{
      cursor: pointer;
    }
  }
}

div.messages.commerce-add-to-cart-confirmation{
  background:white !important;
  padding: 1em;
  color: #555;
  width: 70%;
  max-width: 500px;
  border: 2px dashed #707070;
  margin: 0 auto;
  position: relative;
  .view-header{
    position: absolute;
    top: 0.5em;
    right:0.5em;
    cursor: pointer;
  }
  .button-wrapper{
    display: inline-block !important;
    width: 40% !important;
  }
  .added-product-title{
    text-align: center;
    display: inline-block !important;
    width: 60%;
    vertical-align: top;
    padding: 10px 0px;
  }
  .field-content{
    display:none;
  }
  .commerce-product-title-label{
    display: none;
  }
}
.button{
  &.continue{
  display: none;
  }
}




.node-type-pet-food{
  #body-container{
    #node{
      article{
        width: 100% !important;
      }
    }
  }
}
@media(max-width:755px){
  #content{
    max-width: 100vw !important;
    padding-right: 0px !important;
  }
  #block-menu-menu-oceanic-top-level{
    display: none;
  }
  #product-left{
    padding: 10px;
    box-sizing: border-box;
  }
  #product-right{
    width: 100% !important;
    padding: 10px;
    box-sizing: border-box;

    .page-title{
      float: none;
    }
    #product-price{
      text-align: left !important;
      width: 100%;
      display: block;
      float: none;
    }
  }
  #product-lower{
    #product-lower-links{
      ul{
        li{
          display: block !important;
          margin: 15px 0px;
        }
      }
    }
  }

  #header-container{
    #logo{
      margin-left: 10px !important;
    }
    #basket{
      right: 10px !important;
      top:30px !important;
      width: 30px;
      #basket-text{
        display: none;
      }
    }
  }




}
#product-lower{
  width: 100% !important;
  .detail-panel{
    width: 100% !important;
  }
}


#page-title{
  max-width: 100%;
}
.view-content {
    max-width: 90vw;
    overflow: auto !important;
}

@media(max-width:1000px){
  .view-header{
    display: none;
  }
  .line-item-total{
    text-align: left !important;
  }
  .commerce-line-item-actions {
    text-align: left !important;
    #edit-checkout{
      float: left !important;
    }
  }
}
.cart-item{
  width: 1000px;
}
#views-form-commerce-cart-form-default{
  width: 1000px;
}
